import Vuex, { createLogger } from "vuex";

import auth from "@/vue/apps/shared/store/modules/auth/index.js";
import { getEnv } from "@/vue/utils/solvariConfig.ts";

export const parseDatasetFromHtml = (idSelector, dataset) => {
  const dataEl = document.querySelector(idSelector);
  const dataJson = dataEl?.dataset?.[dataset];

  if (!dataJson) {
    return;
  }

  return JSON.parse(dataJson);
};

export default (baseStore) => {
  const store = new Vuex.Store({
    ...baseStore,
    strict: true,
    modules: {
      auth: auth(),
      ...baseStore.modules,
    },
    plugins: getEnv().config.isDev ? [createLogger()] : [],
  });

  const data = parseDatasetFromHtml(
    "#vue-app-data-access-token",
    "accessToken",
  );
  if (data) {
    store.commit("auth/init", data);
  }

  return store;
};
