import actions from "./actions.js";
import getters from "./getters.js";
import mutations from "./mutations.js";
import initialState from "./state.js";

export default () => ({
  namespaced: true,
  state: initialState(),
  mutations,
  getters,
  actions,
});
