export default () => {
  return {
    accessToken: "",
    expiresAt: "",
    refreshToken: "",
    user: null,
    roles: [],
    permissions: [],
  };
};
