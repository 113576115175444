export default {
  init(state, { accessToken, expiresAt, refreshToken }) {
    state.accessToken = accessToken;
    state.expiresAt = expiresAt;
    state.refreshToken = refreshToken;
  },
  setUser(state, user) {
    state.user = user;
    state.roles = user.roles ?? [];
    state.permissions = user.permissions ?? [];
  },
};
